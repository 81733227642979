<template>
  <div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div v-html="html"></div>
      <div class="row mt-4 d-print-none">
        <div class="col-12">
          <hr />
        </div>
      </div>
      <div v-if="!hidePrintButton" class="row mb-5 d-print-none">
        <div class="col-9"></div>
        <div class="col-3">
          <button class="btn btn-lg btn-outline-primary btn-block" @click="openPrint()">Print or save as PDF</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DeliveryNoteExport',
  components: {

  },
  data() {
    return {
      updating: true,
      hidePrintButton: false,
      html: null,
    }
  },
  computed: {
    orderId(){
      return this.$route.params.orderId;
    },
    storeId(){
      return this.$route.params.storeId;
    },
  },
  mounted() {
    var api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.storeId + "/delivery_note/" + this.orderId + "/";
    const vm = this;
    this.$http({
      method: "GET",
      url: api_url,
    })
    .then(function (response) {
      vm.html = response.data;
      vm.updating = false;
    })
    .catch(function(response){
      vm.html = response.response.data;
      vm.hidePrintButton = true;
      vm.updating = false;
    })
  },
  methods: {
    openPrint(){
      window.print()
    }
  }
}
</script>
